<template>
  <v-container v-if="profile">
    <v-card
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>{{ $t('My information') }}</v-card-title>
      <v-divider />
      <v-card-text class="pt-4 pb-8">
        <v-row>
          <LabeledTextfield
            v-model="profile.first_name"
            :hard-lock="locked"
            cols="6"
            message="First name"
          />
          <LabeledTextfield
            v-model="profile.last_name"
            :hard-lock="locked"
            cols="6"
            message="Last name"
          />
          <LabeledTextfield
            v-model="profile.email"
            :hard-lock="locked"
            message="Email"
          />
          <LabeledTextfield
            v-model="profile.phone"
            :hard-lock="locked"
            message="Cell phone"
          />
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-checkbox
              v-model="profile.text_opted_in"
              :label="
                $t(
                  'I consent to receive communication by text message to the cellphone number provided above.',
                )
              "
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <template v-if="$store.state.pages.Profile.features.enable_profile_edits">
        <v-card-actions>
          <v-btn
            @click="updateProfile"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Update profile') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>

    <v-card
      v-if="$store.state.config.enable_password_authentication"
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>{{ $t('Password') }}</v-card-title>
      <v-divider />
      <v-card-text class="pt-4 pb-8">
        <v-row>
          <LabeledTextfield
            v-model="newPassword"
            :message="$t('New password')"
            cols="6"
            type="password"
          />
          <LabeledTextfield
            v-model="newPasswordConfirmation"
            :message="$t('Confirm new password')"
            cols="6"
            type="password"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="updatePassword"
          :disabled="!newPassword || newPassword != newPasswordConfirmation"
          :loading="processing"
          color="primary"
          size="x-large"
        >
          {{ $t('Update password') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <MFASettingsCard
      v-show="$store.state.config.enable_mfa_authentication"
      @change="load"
      :profile="profile"
    />

    <VerticalSpacer :min-height="60" />
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import MFASettingsCard from '@/shared/components/MFASettingsCard.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();

const newPassword = ref(null);
const newPasswordConfirmation = ref(null);
const processing = ref(false);
const profile = ref(null);

const locked = computed(() => {
  return !store.state.pages.Profile.features.enable_profile_edits;
});

onMounted(load);

async function load() {
  const { data } = await Api.manager.profile.get();
  profile.value = data;
}

function updatePassword() {
  processing.value = true;
  Api.manager.profile.update(
    { password: newPassword.value },
    (resp) => {
      profile.value = resp.data;
      eventBus.chime('Password saved');
      processing.value = false;
    },
    (err) => {
      eventBus.chime(err.response.data.errors.join('. '));
      processing.value = false;
    },
  );
}

function updateProfile() {
  processing.value = true;
  Api.manager.profile.update(
    profile.value,
    (resp) => {
      profile.value = resp.data;
      eventBus.chime('Profile saved');
      processing.value = false;
    },
    (err) => {
      eventBus.chime(err.response.data.errors.join('. '));
      processing.value = false;
    },
  );
}
</script>
