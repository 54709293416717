<template>
  <div v-if="course && lesson && assignments.length > 0">
    <v-navigation-drawer width="350">
      <LessonHeader
        :assignment-completions="assignmentCompletions"
        :assignments="assignments"
        :course="course"
        :lesson="lesson"
      />

      <v-divider class="mb-4" />

      <AssignmentNavigator
        @transition="transition($event)"
        :assignment-completions="assignmentCompletions"
        :assignments="assignments"
        :course="course"
        :current-assignment="assignment"
        :current-assignment-index="assignmentIndex"
      />
    </v-navigation-drawer>

    <v-main class="px-2 py-4">
      <template v-if="course && lesson && assignments.length > 0">
        <v-row
          dense
          no-gutters
        >
          <v-col
            v-show="$vuetify.display.mdAndUp || tab != false"
            cols="12"
          >
            <div>
              <v-container class="mxw-1000 px-2 py-0">
                <transition
                  mode="out-in"
                  name="slide-left"
                >
                  <AssignmentShow
                    v-if="assignment"
                    @back="transitionBack"
                    @close="tab = false"
                    @reload="reload()"
                    @video-progress="updateVideoProgress($event)"
                    :assignment="assignment"
                    :assignment-completion="assignmentCompletion"
                    :course="course"
                    :tab="tab"
                  />
                </transition>

                <v-bottom-navigation
                  class="border-t"
                  grow
                >
                  <v-btn
                    @click="transitionBack"
                    size="x-large"
                  >
                    {{ $t('Back') }}
                  </v-btn>

                  <v-spacer />

                  <v-btn
                    @click="transitionForward"
                    :disabled="!assignmentCompletion?.completed && !allButVideoComplete"
                    class="bg-primary"
                    size="x-large"
                    rounded
                  >
                    {{ $t(nextButtonText) }}
                  </v-btn>
                </v-bottom-navigation>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row class="pa-12">
          <v-col class="d-flex justify-center">
            <v-progress-circular
              color="primary"
              size="50"
              indeterminate
            />
          </v-col>
        </v-row>
      </template>
    </v-main>
  </div>
</template>

<script setup>
import AssignmentNavigator from '@/manager/components/assignments/AssignmentNavigator.vue';
import AssignmentShow from '@/manager/components/assignments/AssignmentShow.vue';
import LessonHeader from '@/manager/components/lessons/LessonHeader.vue';
import ProviderApi from '@/manager/services/bright_finder';
import SharedApi from '@/shared/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import vuetify from '@/plugins/vuetify';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const assignment = ref(null);
const assignments = ref([]);
const assignmentCompletion = ref(null);
const assignmentCompletions = ref([]);
const course = ref(null);
const courseCompletion = ref(null);
const lesson = ref(null);
const tab = ref(null);

watch(route.params.lessonId, load, { immediate: true });

const assignmentIndex = computed(() => {
  if (!assignment.value) return null;

  return assignments.value.indexOf(assignment.value);
});

const cohortId = computed(() => {
  return route.params.cohortId || route.query.cohortId;
});

const nextButtonText = computed(() => {
  return assignments.value.length === assignmentIndex.value + 1 ? 'Finish' : 'Next';
});

const allButVideoComplete = computed(() => {
  if (!assignment.value || !assignmentCompletion.value) return false;
  if (!assignment.value.assets?.en) return false;

  const requiresVideo = assignment.value.assets.en.split('.').pop().toLowerCase() === 'mp4'; // TODO: Update to use array of supported video types
  if (!requiresVideo) return false;

  const requiresComments = assignment.value.required_comment_count > 0;
  const requiresReplies = assignment.value.required_comment_reply_count > 0;
  const requiresHomework = !!assignment.value.required_homework_file_upload;

  if (
    requiresComments &&
    assignmentCompletion.value.comment_count < assignment.value.required_comment_count
  )
    return false;
  if (
    requiresReplies &&
    assignmentCompletion.value.comment_reply_count < assignment.value.required_comment_reply_count
  )
    return false;
  if (requiresHomework && !assignmentCompletion.value.homework_attachment_id) return false;

  return assignmentCompletion.value.video_progress != 100;
});

async function findOrCreateAssignmentCompletion() {
  assignmentCompletion.value = assignmentCompletions.value.find(
    (localAssignmentCompletion) => localAssignmentCompletion.assignment_id === assignment.value?.id,
  );

  if (!assignmentCompletion.value) {
    const params = {
      course_completion_id: courseCompletion.value.id,
      assignment_id: assignment.value?.id,
      completed: false,
    };
    const response = await SharedApi.assignmentCompletion
      .create(params)
      .catch((error) => eventBus.error(error));

    const responseAssignmentCompletion = response?.data;
    assignmentCompletions.value.push(responseAssignmentCompletion);
    assignmentCompletion.value = responseAssignmentCompletion;
  }
}

async function load() {
  tab.value = null;

  await loadCourse();
  await loadCourseCompletion();
  await loadLesson();

  await loadAssignments();
  assignment.value = assignments.value.find(
    (existingAssignment) => existingAssignment.id === route.query.assignmentId,
  );
  tab.value = assignment.value?.id;

  await loadAssignmentCompletions();
}

async function loadCourse() {
  const response = await SharedApi.public_api.organization.course.get(route.params.courseId);
  course.value = response.data;
}

async function loadLesson() {
  const response = await SharedApi.public_api.organization.lesson.get(route.params.lessonId);
  lesson.value = response.data;
}

async function loadAssignments() {
  const response = await SharedApi.public_api.organization.assignment.index({
    lesson_id: route.params.lessonId,
  });
  assignments.value = response.data;
}

async function loadCourseCompletion() {
  const response = await ProviderApi.manager.course_completion.index({
    course_id: route.params.courseId,
  });
  [courseCompletion.value] = response.data;
}

async function loadAssignmentCompletions() {
  const response = await SharedApi.assignmentCompletion.index({
    course_completion_id: courseCompletion.value.id,
    lesson_id: lesson.value.id,
  });
  assignmentCompletions.value = response.data;

  await findOrCreateAssignmentCompletion();
}

async function reload() {
  await loadAssignmentCompletions();
}

async function transition(transitionAssignment) {
  assignment.value = null;
  tab.value = null;

  if (route.query.assignmentId !== transitionAssignment.id) {
    router.push({
      query: {
        courseCompletionId: courseCompletion.value.id,
        assignmentId: transitionAssignment.id,
        lessonId: lesson.value.id,
        cohortId: cohortId.value,
      },
    });
  }

  setTimeout(async () => {
    assignment.value = transitionAssignment;
    tab.value = assignment.value?.id;
    await findOrCreateAssignmentCompletion();
  }, 400);
}

async function transitionForward() {
  if (validateForward()) {
    const index = assignments.value.indexOf(assignment.value);

    if (assignments.value.length > index + 1) {
      const nextAssignment = assignments.value[index + 1];
      await transition(nextAssignment);
    } else {
      router.push({
        name: 'CourseShow',
        params: {
          courseId: course.value.id,
          cohortId: cohortId.value,
        },
      });
    }
  }
}

async function transitionBack() {
  const index = assignments.value.indexOf(assignment.value);
  if (index > 0) {
    await transition(assignments.value[index - 1]);
  } else {
    router.push({
      name: 'CourseShow',
      params: {
        courseId: course.value.id,
        cohortId: cohortId.value,
      },
    });
  }
}

async function updateAssignmentCompletion(params) {
  const response = await SharedApi.assignmentCompletion
    .update(assignmentCompletion.value.id, params)
    .catch((error) => eventBus.error(error));
  if (!response.data) return;

  assignmentCompletion.value = response.data;

  const assignmentCompletionIndex = assignmentCompletions.value.findIndex(
    (currentCompletion) => currentCompletion.id === assignmentCompletion.value.id,
  );

  if (assignmentCompletionIndex !== -1) {
    assignmentCompletions.value[assignmentCompletionIndex] = assignmentCompletion.value;
  }
}

async function updateVideoProgress(watchedProgress) {
  await updateAssignmentCompletion({
    video_progress: watchedProgress,
  });
}

function validateForward() {
  if (allButVideoComplete.value) {
    eventBus.longChime('You must finish the video content before progressing.');
    return false;
  }
  return true;
}

onMounted(() => {
  tab.value = route.query.tab || (vuetify.display.mobile.value ? false : 'overview');
});
</script>
