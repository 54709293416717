<template>
  <div v-if="subsidyPrograms">
    <template v-if="subsidyPrograms.length > 1">
      <v-row class="bg-primary pt-3 mb-2">
        <v-col class="d-flex justify-center py-6">
          <v-select
            v-model="selectedSubsidyProgram"
            @update:model-value="onSubsidyProgramChange()"
            :item-title="(subsidyProgram) => t(subsidyProgram.name)"
            :items="subsidyPrograms"
            class="mxw-400"
            density="comfortable"
            item-value="id"
            variant="solo"
            hide-details
            return-object
          />
        </v-col>
      </v-row>
    </template>

    <v-container
      v-if="enrollmentRequests"
      class="mxw-1400"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-title role="heading">
          {{ t(store.state.pages.Interested.title || 'Interested') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-alert
            v-if="store.state.site.features.enable_tours && providerRequiresSetup"
            @click="
              router.push({
                name: 'ProviderShow',
                params: { providerId: providerRequiresSetup.id },
                query: { tab: 'tours' },
              })
            "
            border="start"
            class="pointer"
            color="primary"
            elevation="2"
            icon="notification_important"
            prominent
          >
            <v-row class="d-flex align-center">
              <v-col class="grow">
                <span class="fs-18">
                  {{ t('Get more interested families by enabling tour scheduling') }}
                </span>
              </v-col>

              <v-col class="ta-right">
                <v-icon
                  color="primary"
                  size="30"
                >
                  east
                </v-icon>
              </v-col>
            </v-row>
          </v-alert>

          <div class="d-flex align-center justify-center my-4">
            <v-text-field
              v-model="query"
              :aria-label="t('Search interested families and children by name or child ID')"
              :placeholder="t('Search interested families and children by name or child ID')"
              class="bg-white"
              prepend-inner-icon="search"
              variant="solo-filled"
              flat
              hide-details
            />
            <v-btn
              v-if="store.state.pages.Interested.features.enable_create_new"
              @click="$refs.newDialog.open()"
              :aria-label="t('Create new')"
              class="ms-3"
              color="primary"
              data-cy="add-button"
              elevation="0"
              size="small"
              variant="text"
              icon
            >
              <v-icon>add</v-icon>
            </v-btn>

            <ResourceDialog
              @save="create"
              ref="newDialog"
              :fields="newFields"
              :max-width="600"
              :processing="processing"
              title="Add"
            />
          </div>

          <v-row class="d-flex align-center mb-0">
            <v-col
              class="d-flex align-center"
              cols="12"
              md="6"
            >
              <v-btn
                @click="selectAll()"
                class="me-3"
                color="primary"
                data-cy="select-all-button"
                elevation="0"
              >
                <span>{{ t('Select all') }}</span>
              </v-btn>

              <v-menu>
                <template #activator="{ props }">
                  <v-btn
                    v-show="displayEditCheckedButton"
                    v-bind="props"
                    :loading="processing"
                    color="primary"
                    data-cy="edit-button"
                    elevation="2"
                  >
                    <span>{{ t('Edit') }}</span>
                  </v-btn>
                </template>
                <v-list
                  v-show="displayEditCheckedOptions"
                  data-testid="edit-checked-options"
                >
                  <v-list-item
                    v-for="status in pendingStatusesDirectEnrollment"
                    @click="setStatusForAll(status.value)"
                    :key="status.value"
                  >
                    <v-list-item-title>
                      <span class="me-1">
                        {{ t('Change to') }}
                      </span>

                      <span>{{ t(status.text) }}</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="draftEnrollAll()"
                    :disabled="enrollAllDisabled"
                  >
                    <v-list-item-title>{{ t(terms.enroll) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                v-if="store.state.pages.Interested.features.enable_mass_messaging"
                v-show="checkedEnrollmentRequests.length > 0"
                @click="$refs.newMessageDialog.open()"
                :loading="processing"
                class="ms-3"
                color="primary"
                data-cy="message-button"
                elevation="2"
              >
                <span>{{ t('Message') }}</span>
              </v-btn>

              <v-btn
                v-show="areValidEnrollmentsWithoutSubsidyPrograms()"
                @click="$refs.transferSelectedEnrollmentsDialog.open()"
                class="ms-3"
                color="primary"
                data-cy="transfer-button"
                elevation="2"
              >
                <span>{{ t('Transfer') }}</span>
              </v-btn>

              <ResourceDialog
                @save="sendMassMessage"
                ref="newMessageDialog"
                :processing="processing"
                :save-button-disabled="!massMessage.subject || !massMessage.body"
                save-button-text="Send"
                title="New message"
              >
                <template #form>
                  <v-row>
                    <LabeledTextfield
                      v-model="massMessage.subject"
                      message="Subject"
                    />

                    <LabeledTextarea
                      v-model="massMessage.body"
                      message="Body"
                    />
                  </v-row>
                </template>
              </ResourceDialog>

              <ResourceDialog
                @save="transferSelectedEnrollments()"
                ref="transferSelectedEnrollmentsDialog"
                :max-width="800"
                :processing="processing"
                :save-button-text="t('Yes, transfer children')"
                :title="t('Transfer children')"
                closeable
              >
                <template #form>
                  <v-row>
                    <span>
                      {{
                        t(
                          'Selecting "Yes, transfer children" will move all children to the chosen location. Their status will not change.',
                        )
                      }}
                    </span>

                    <v-divider />

                    <LabeledSimpleSelect
                      v-model="selectedEnrollAllProviderId"
                      :items="providers"
                      :message="t('Transfer to:')"
                      :placeholder="t('Select a location...')"
                      data-cy="provider-transfer-dropdown"
                      item-title="name"
                      item-value="id"
                      variant="filled"
                    />
                  </v-row>
                </template>
              </ResourceDialog>

              <v-pagination
                v-if="pages > 1"
                v-model="page"
                :length="pages"
                :total-visible="8"
                class="d-inline-block"
              />
            </v-col>

            <v-col
              class="d-flex justify-end"
              cols="12"
              md="6"
            >
              <FilterMenu
                ref="providerFilter"
                :actions="false"
                :title="filteredProviderName || 'Location'"
                classes="ms-3"
                elevation="0"
                width="400"
                borderless
                left
                outlined
              >
                <template #card>
                  <v-autocomplete
                    v-model="filters.provider_id"
                    @update:model-value="$refs.providerFilter.close()"
                    :item-title="providerNameWithAddress"
                    :items="providers"
                    :placeholder="t('Search by name or address')"
                    item-value="id"
                    variant="filled"
                    auto-select-first
                    clearable
                    left
                  />
                </template>
              </FilterMenu>

              <template
                v-if="selectedSubsidyProgram?.enable_deferred_acceptance && filteredProviderName"
              >
                <FilterMenu
                  :actions="false"
                  :title="getProgramText(filters.program_id) || terms.program"
                  classes="ms-3"
                  elevation="0"
                  borderless
                  left
                  outlined
                >
                  <template #card>
                    <v-list
                      v-model:selected="filters.program_id"
                      density="compact"
                    >
                      <v-list-item
                        v-for="program in programs"
                        :key="program.id"
                        :title="t(program.name)"
                        :value="program.id"
                        color="primary"
                        data-cy="availability-checkmark"
                      >
                        <template #prepend="{ isSelected, select }">
                          <v-checkbox-btn
                            @update:model-value="select"
                            :model-value="isSelected"
                            density="compact"
                            tabindex="-1"
                          >
                            <template #input="{ inputNode, icon }">
                              <v-icon
                                :icon="icon"
                                size="x-small"
                              />

                              <component :is="inputNode" />
                            </template>
                          </v-checkbox-btn>
                        </template>
                      </v-list-item>
                    </v-list>
                  </template>
                </FilterMenu>
              </template>

              <FilterMenu
                :actions="false"
                :title="getStatusText(filters.status) || 'Status'"
                classes="ms-3"
                elevation="0"
                borderless
                left
                outlined
                paddingless
              >
                <template #card>
                  <v-list
                    v-model:selected="filters.status"
                    density="compact"
                  >
                    <v-list-item
                      v-for="status in allStatusesDirectEnrollment"
                      :key="status.value"
                      :title="t(status.text)"
                      :value="status.value"
                      color="primary"
                      data-cy="availability-checkmark"
                    >
                      <template #prepend="{ isSelected, select }">
                        <v-checkbox-btn
                          @update:model-value="select"
                          :model-value="isSelected"
                          density="compact"
                          tabindex="-1"
                        >
                          <template #input="{ inputNode, icon }">
                            <v-icon
                              :icon="icon"
                              size="x-small"
                            />

                            <component :is="inputNode" />
                          </template>
                        </v-checkbox-btn>
                      </template>
                    </v-list-item>
                  </v-list>
                </template>
              </FilterMenu>

              <v-menu location="left">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    :loading="processing"
                    icon="more_vert"
                    size="small"
                    variant="text"
                  />
                </template>

                <v-list>
                  <v-list-item @click="download('csv')">
                    <v-list-item-title>
                      {{ t('Export to CSV for Excel') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <ResourceDialog
                @save="closeInviteDialog()"
                ref="inviteDialog"
                :cancellable="false"
                :closeable="true"
                :max-width="600"
                :save-button-disabled="programs.length === 0"
                save-button-text="Done"
                sub-title="Use this referral link to invite a child to apply to your program."
                title="Invite a child"
                hide-actions
              >
                <template #form>
                  <v-row>
                    <v-col cols="12">
                      <div class="fs-14">
                        {{ `${t('Select a location')}:` }}
                      </div>

                      <v-select
                        v-model="selectedInviteProviderId"
                        :items="providers"
                        class="b-radius-0"
                        data-testid="select-provider"
                        item-title="name"
                        item-value="id"
                        variant="solo"
                        clearable
                      />
                    </v-col>
                  </v-row>

                  <div v-if="selectedInviteProviderId">
                    <v-row
                      v-for="program in inviteProgramOptions"
                      :key="program?.id"
                      dense
                    >
                      <CopyableInput
                        :label="program?.name"
                        :value="buildInviteLink(program)"
                      />
                    </v-row>
                  </div>
                </template>
              </ResourceDialog>

              <v-btn
                v-if="selectedSubsidyProgram?.enable_provider_program_referral_links === true"
                @click="$refs.inviteDialog.open()"
                class="focus-very-visible"
                color="primary"
                data-testid="provider-invite-btn"
                tabindex="0"
              >
                <v-icon> people </v-icon>

                <span class="pl-2">
                  {{ t('Invite') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>

          <v-card
            class="mb-2"
            border
            flat
            tile
          >
            <v-card-text class="fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="filterSummary">
                    <v-icon
                      icon="filter_alt"
                      start
                    />

                    <span class="me-1"> {{ t('Filtering by') }}: </span>

                    <span class="fw-600">
                      {{ t(filterSummary) }}
                    </span>

                    <span>.&nbsp;</span>
                  </template>
                  <span>
                    {{ t('Displaying first') }} <strong>{{ enrollmentRequests.length }}</strong>
                    {{ t('results') }}, {{ t('out of') }} <strong>{{ total }}</strong>
                    {{ t('total results') }}.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            class="px-2 py-4"
            border
            flat
            tile
          >
            <v-row>
              <SortableTableHeader
                @check="selectAll()"
                @toggle="toggleSort($event)"
                :check-value="allChecked"
                :checkable="true"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="name"
                md="3"
                title="Child"
                check-locked
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="dob"
                md="1"
                title="DOB"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="address"
                md="2"
                title="Home"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="provider"
                md="2"
                title="Location"
              />

              <template v-if="selectedSubsidyProgram?.enable_deferred_acceptance">
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  :title="terms.program"
                  field="program"
                  md="1"
                />
              </template>

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="status"
                md="1"
                title="Status"
              />

              <template v-if="selectedSubsidyProgram?.uses_enrollment_due_dates">
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="due_date"
                  md="1"
                  title="Due"
                />
              </template>

              <template v-if="waitlistEnabled">
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="waitlist"
                  md="2"
                  title="Waitlist"
                />
              </template>
            </v-row>
          </v-card>

          <template v-if="enrollmentRequests.length == 0">
            <NullState
              class="my-8"
              hide-new-button
            />
          </template>

          <div
            v-for="enrollment in enrollmentRequests"
            @click="updateQuery({ enrollmentId: enrollment.id })"
            :key="enrollment.id"
            class="pointer"
          >
            <EnrollmentRequestRow
              @check="enrollment.checked = !enrollment.checked"
              @display="displayStatus(enrollment)"
              @waitlist="setWaitlistNumber(enrollment, $event)"
              :enable-deferred-acceptance="selectedSubsidyProgram?.enable_deferred_acceptance"
              :enrollment="enrollment"
              class="my-2"
            />
          </div>

          <v-pagination
            v-if="pages > 1"
            v-model="page"
            :length="pages"
            :total-visible="8"
            class="mt-4"
          />
        </v-card-text>
      </v-card>

      <VerticalSpacer :min-height="50" />

      <EnrollmentRequestDialog
        @change="load"
        @decline="clearAndReload"
        ref="detailsDialog"
        :providers="providers"
        :statuses="pendingStatusesDirectEnrollment"
        :subsidy-program="selectedSubsidyProgram"
      />

      <!-- TODO: Move into sub-component -->
      <v-dialog
        v-model="enrollAllDialogIsVisible"
        max-width="500"
        scrim="transparent"
      >
        <v-card
          v-if="checkedEnrollmentRequests.length > 0"
          class="mx-auto"
          max-width="500"
        >
          <v-card-title> {{ terms.enroll }} {{ t('all selected') }} </v-card-title>

          <v-card-text>
            <p class="c-black fs-16 fw-500 mb-2">
              {{ t('Location') }}
            </p>

            <v-select
              v-model="selectedEnrollAllProviderId"
              :disabled="!store.state.pages.Interested.features.enable_provider_transfer"
              :items="providers"
              :readonly="!store.state.pages.Interested.features.enable_provider_transfer"
              item-title="name"
              item-value="id"
              variant="filled"
            />

            <div
              v-if="store.state.pages.Interested.features.enable_program_assignment"
              class="mb-6"
            >
              <p class="c-black fs-16 fw-500 mb-2">
                {{ t(`What program or class will they ${terms.enroll.toLowerCase()} in?`) }}
              </p>

              <v-select
                v-model="programId"
                :disabled="!selectedEnrollAllProviderId"
                :items="enrollAllProgramOptions"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn
              @click="enrollAll"
              :loading="processing"
              color="primary"
              size="x-large"
              block
            >
              {{ t(`Yes, ${terms.enroll.toLowerCase()} these students`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <template v-else>
      <v-progress-linear
        class="mt-6 mxw-1400 mx-auto"
        indeterminate
      />
    </template>
  </div>
</template>

<script setup>
import { scrollTo } from 'vuetify/lib/composables/goto';

import CopyableInput from '@/shared/components/provider/CopyableInput.vue';
import EnrollmentRequestDialog from '@/manager/components/enrollment/EnrollmentRequestDialog.vue';
import EnrollmentRequestRow from '@/manager/components/enrollment/EnrollmentRequestRow.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

import { createDateTimeFileName } from '@/shared/services/date-time-file-name';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';

import api from '@/shared/services/all_bright_finder';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import useTerms from '@/shared/composables/useTerms';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { updateQuery } = useRouterHelper();
const { t } = useI18n();
const { terms } = useTerms();
const { allStatuses, getStatusText, pendingStatuses, selectedStatusObject } =
  useEnrollmentStatuses();

const detailsDialog = ref(null);
const enrollAllDialogIsVisible = ref(false);
const enrollAllProgramOptions = ref([]);
const enrollmentRequests = ref(null);
const filters = ref({
  provider_id: route.query.providerId,
  program_id: route.query.programId,
  status: route.query.status,
  sort_dir: 'asc',
  sort_field: 'waitlist',
});
const inviteDialog = ref(null);
const inviteProgramOptions = ref([]);
const massMessage = ref({ subject: null, body: null });
const newDialog = ref(null);
const newMessageDialog = ref(null);
const page = ref(1);
const pages = ref(null);
const processing = ref(false);
const programId = ref(null);
const programs = ref([]);
const providerFilter = ref(null);
const providerRequiresSetup = ref(null);
const providers = ref([]);
const query = ref(null);
const queryCount = ref(0);
const selectedEnrollAllProviderId = ref(null);
const selectedInviteProviderId = ref(null);
const selectedSubsidyProgram = ref(null);
const subsidyPrograms = ref(null);
const transferSelectedEnrollmentsDialog = ref(null);
const total = ref(0);

const allChecked = computed(() => {
  return (
    enrollmentRequests.value.length > 0 &&
    checkedEnrollmentRequests.value.length === enrollmentRequests.value.length
  );
});

const checkedEnrollmentRequests = computed(() => {
  return enrollmentRequests.value.filter((enrollmentRequest) => enrollmentRequest.checked);
});

const displayEditCheckedButton = computed(() => {
  if (selectedSubsidyProgram.value?.enable_deferred_acceptance) return false;

  return checkedEnrollmentRequests.value.length > 0;
});

const displayEditCheckedOptions = computed(() => {
  return checkedEnrollmentRequests.value.length > 0;
});

const enrollAllDisabled = computed(() => {
  return checkedEnrollmentRequests.value.some(
    (enrollmentRequest) =>
      enrollmentRequest.provider_id !== checkedEnrollmentRequests.value[0].provider_id,
  );
});

const filterSummary = computed(() => {
  const appliedFilters = [];
  if (filteredProviderName.value) appliedFilters.push(filteredProviderName.value);
  if (filters.value.status) appliedFilters.push(t(getStatusText(filters.value.status)));
  if (filteredProgramName.value) appliedFilters.push(filteredProgramName.value);

  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
});

const allStatusesDirectEnrollment = computed(() => {
  return addSelectedIfDirectEnrollment(allStatuses.value, selectedSubsidyProgram.value);
});

const pendingStatusesDirectEnrollment = computed(() => {
  return addSelectedIfDirectEnrollment(pendingStatuses.value, selectedSubsidyProgram.value);
});

const newFields = computed(() => {
  return [
    {
      cols: '6',
      text: 'Parent first name',
      value: 'primary_first_name',
      required: true,
    },
    {
      cols: '6',
      text: 'Parent last name',
      value: 'primary_last_name',
      required: true,
    },
    { text: 'Parent email', value: 'primary_email' },
    { text: 'Parent phone', value: 'primary_phone' },
    {
      cols: '6',
      text: 'Child first name',
      value: 'child_first_name',
      required: true,
    },
    {
      cols: '6',
      text: 'Child last name',
      value: 'child_last_name',
      required: true,
    },
    {
      text: 'Child DOB',
      value: 'child_dob',
      required: true,
      type: 'dob',
    },
    {
      text: 'Location',
      value: 'provider_id',
      required: true,
      items: providers.value,
      itemText: 'name',
      itemValue: 'id',
    },
  ];
});

const filteredProgramName = computed(() => {
  if (filters.value.program_id && filters.value.provider_id) {
    return programs.value.find((program) => program.id === filters.value.program_id)?.name;
  }

  return null;
});

const filteredProviderName = computed(() => {
  if (filters.value.provider_id && providers.value.length > 0) {
    return providers.value.find((provider) => provider.id === filters.value.provider_id)?.name;
  }

  return null;
});

const waitlistEnabled = computed(() => {
  return store.state.pages.Interested.features.enable_waitlist;
});

watch(
  () => filters.value.provider_id,
  async () => {
    await loadPrograms(filters.value.provider_id);

    if (filters.value.program_id) {
      filters.value.program_id = null;
      return; // The change above will trigger watch that calls load
    }

    if (page.value !== 1) {
      page.value = 1;
      return; // The change above will trigger watch that calls load
    }

    await load();
  },
  {
    immediate: true,
  },
);

watch(
  () => filters.value.status,
  async () => {
    if (page.value !== 1) {
      page.value = 1;
      return; // The change above will trigger watch that calls load
    }

    await load();
  },
);

watch(
  () => filters.value.program_id,
  async () => {
    if (page.value !== 1) {
      page.value = 1;
      return; // The change above will trigger watch that calls load
    }

    await load();
  },
);

watch(selectedSubsidyProgram, () => {
  const currentStatusObjectValue = filters.value?.status;
  const noMatch = allStatusesDirectEnrollment.value.every(
    (filterableStatus) => filterableStatus.value !== currentStatusObjectValue,
  );

  if (currentStatusObjectValue && noMatch) {
    filters.value.status = null;
  }
});

watch(page, async () => {
  await load();
  scrollTo(0);
});

watch(query, async (newValue, oldValue) => {
  if (newValue != oldValue) {
    if (page.value !== 1) {
      page.value = 1;
      return; // The change above will trigger watch that calls load
    }

    await load();
  }
});

watch(selectedEnrollAllProviderId, (newValue) => loadEnrollAllProgramOptions(newValue));

watch(selectedInviteProviderId, (newValue) => loadInviteProgramOptions(newValue));

onMounted(async () => {
  await load();
});

function closeInviteDialog() {
  selectedInviteProviderId.value = null;
  inviteDialog.value?.close();
}

function buildInviteLink(program) {
  const baseUrl = store.state.config?.parent_url;
  const { subsidyProgramId } = route.query;
  const path = `/provider_referral?subsidyProgramId=${subsidyProgramId}&programId=${program?.id}`;

  return `${baseUrl}${path}`;
}

async function clearAndReload() {
  await load();
  detailsDialog.value?.close();
}

function create(newReq) {
  if (!newReq.primary_email && !newReq.primary_phone) {
    return eventBus.chime('A parent email or phone number is required');
  }

  processing.value = true;

  const thisNewRew = newReq;
  thisNewRew.status = 'Selected';

  api.manager.enrollment.create(
    thisNewRew,
    async () => {
      newDialog.value.close();
      processing.value = false;
      eventBus.chime('Added');
      await load();
    },
    (error) => {
      processing.value = false;
      if (error.response.data.errors[0] === 'Family already exists') {
        eventBus.chime(store.state.pages.Interested.content.duplicate_family_message);
      } else {
        eventBus.chime(error.response.data.errors[0]);
      }
    },
  );
  return true;
}

function getProgramText(programValue) {
  return programs.value.find((program) => program.id === programValue)?.name || programValue;
}

function draftEnrollAll() {
  selectedEnrollAllProviderId.value = checkedEnrollmentRequests.value[0].provider_id;
  enrollAllDialogIsVisible.value = true;
}

function areValidEnrollmentsWithoutSubsidyPrograms() {
  const validTransferStatuses = ['Selected', 'Proposed', 'Placed', 'Offered', 'Accepted'];
  if (checkedEnrollmentRequests.value.length > 0) {
    return checkedEnrollmentRequests.value.every(
      (enrollment) =>
        enrollment.subsidy_program_id == null && validTransferStatuses.includes(enrollment.status),
    );
  }
}

async function download(format) {
  const downloadParams = {};

  downloadParams.subsidy_program_id = selectedSubsidyProgram.value?.id;
  if (filters.value.provider_id) {
    downloadParams.provider_id = filters.value.provider_id;
  }
  downloadParams.status =
    filters.value.status || pendingStatusesDirectEnrollment.value.map((status) => status.value);
  const filename = createDateTimeFileName(
    store.state.pages.Interested.title || 'Interested',
    store.state.profile.default_locale,
  );
  const url = await api.manager.enrollment.downloadUrl(downloadParams, filename, format);

  window.open(url);
}

async function enrollAll() {
  processing.value = true;

  const providerId = checkedEnrollmentRequests.value[0].provider_id;
  await Promise.all(
    checkedEnrollmentRequests.value.map(async (enrollmentRequest) => {
      api.manager.enrollment.update(enrollmentRequest.id, {
        provider_id: providerId,
        program_id: programId.value,
        status: ENROLLMENT_STATUSES.ENROLLED,
      });
    }),
  );
  setTimeout(async () => {
    enrollAllDialogIsVisible.value = false;
    await load();
  }, 2000);
}

async function load() {
  processing.value = true;
  await setSubsidyProgramIdQuery();
  await loadSubsidyPrograms();

  await loadProviders();
  if (
    filters.value.provider_id &&
    !providers.value.find((provider) => provider.id === filters.value.provider_id)
  ) {
    filters.value.provider_id = null;
    return; // The change above will trigger watch that calls load
  }

  filters.value.page = page.value;
  filters.value.query = query.value;
  queryCount.value += 1;
  const currentQueryCount = queryCount.value;
  const newFilters = { ...filters.value };

  if (!newFilters.status)
    newFilters.status = pendingStatusesDirectEnrollment.value.map((status) => status.value);

  if (selectedSubsidyProgram.value?.id)
    newFilters.subsidy_program_id = selectedSubsidyProgram.value?.id;

  api.manager.enrollment.index({ ...newFilters, include: 'computed_subsidy_flags' }, (response) => {
    if (queryCount.value === currentQueryCount) {
      enrollmentRequests.value = response.data.map((enrollmentRequest) => {
        const thisEr = enrollmentRequest;
        thisEr.checked = false;
        return thisEr;
      });
      pages.value = parseInt(response.headers['x-page-count'] || 0, 10);
      total.value = parseInt(response.headers['x-total-count'] || 0, 10);
      processing.value = false;
    }
  });
}

async function loadEnrollAllProgramOptions(providerId) {
  if (!providerId) {
    enrollAllProgramOptions.value = [];
    return;
  }

  const programFilters = {
    subsidy_program_id: selectedSubsidyProgram.value?.id,
  };
  const { data } = await api.program.index(providerId, programFilters);
  enrollAllProgramOptions.value = data;
}

async function loadInviteProgramOptions(providerId) {
  if (!providerId) {
    inviteProgramOptions.value = [];
    return;
  }

  const programFilters = {
    subsidy_program_id: selectedSubsidyProgram.value?.id,
  };
  const { data } = await api.program.index(providerId, programFilters);
  inviteProgramOptions.value = data;
}

async function loadPrograms(providerId) {
  if (!providerId) {
    programs.value = [];
    return;
  }

  const programFilters = {};
  if (selectedSubsidyProgram.value)
    programFilters.subsidy_program_id = selectedSubsidyProgram.value.id;

  const { data } = await api.program.index(providerId, programFilters);
  programs.value = data;
}

async function loadProviders() {
  const providerFilters = {};
  if (selectedSubsidyProgram.value)
    providerFilters.subsidy_program_id = selectedSubsidyProgram.value.id;

  const { data } = await api.manager.provider.index(providerFilters);
  providers.value = data;
}

async function loadSubsidyPrograms() {
  if (subsidyPrograms.value) return;

  const response = await api.public_api.organization.subsidy_program.index();
  subsidyPrograms.value = response.data;

  if (!selectedSubsidyProgram.value && !!store.state.filters.subsidy?.subsidyProgramId) {
    selectedSubsidyProgram.value = subsidyPrograms.value.find(
      (subsidyProgram) => subsidyProgram.id === store.state.filters.subsidy.subsidyProgramId,
    );
    await setSubsidyProgramIdQuery();
  } else if (route.query.subsidyProgramId) {
    selectedSubsidyProgram.value = subsidyPrograms.value.find(
      (subsidyProgram) => subsidyProgram.id === route.query.subsidyProgramId,
    );
  } else {
    [selectedSubsidyProgram.value] = subsidyPrograms.value;
    await setSubsidyProgramIdQuery();
  }
}

function addSelectedIfDirectEnrollment(statusObjects, subsidyProgram) {
  // This is a temporary implementation for CO UPK 25-26 launch as they need to ensure direct enrollment can still
  // access 24-25 Selected enrollments, but not have Selected enrollments available in 25-26 subsidy program.
  // It handles when organization.excluded_provider_enrollment_statuses includes 'Selected' because it must
  // not be available in most use cases, but we need to squeeze it back in for direct enrollment
  if (subsidyProgram?.enable_provider_direct_enrollment_of_students) {
    const selectedAlreadyPresent = statusObjects.some(
      (status) => status.value === selectedStatusObject.value.value,
    );
    if (!selectedAlreadyPresent) {
      return [...statusObjects, selectedStatusObject.value];
    }
  }

  return statusObjects;
}

async function onSubsidyProgramChange() {
  selectedInviteProviderId.value = null;
  inviteProgramOptions.value = [];
  await load();
}

function providerNameWithAddress(provider) {
  return [provider.name, provider.address1].filter(Boolean).join(' - ');
}

function selectAll() {
  const check = checkedEnrollmentRequests.value.length < enrollmentRequests.value.length;
  // eslint-disable-next-line no-return-assign, no-param-reassign
  enrollmentRequests.value.forEach((enrollmentRequest) => (enrollmentRequest.checked = check));
}

function sendMassMessage() {
  processing.value = true;
  massMessage.value.enrollment_ids = checkedEnrollmentRequests.value.map(
    (enrollmentRequest) => enrollmentRequest.id,
  );
  api.manager.mass_message.create(
    massMessage.value,
    async () => {
      newMessageDialog.value.close();
      processing.value = false;
      eventBus.chime('Message sent');
      await load();
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors);
    },
  );
}

async function setStatusForAll(status) {
  processing.value = true;
  await Promise.all(
    checkedEnrollmentRequests.value.map(async (enrollmentRequest) =>
      api.manager.enrollment.update(enrollmentRequest.id, { status }),
    ),
  );

  processing.value = false;
  await load();
}

async function setSubsidyProgramIdQuery() {
  if (!selectedSubsidyProgram.value) return;

  await updateQuery({ subsidyProgramId: selectedSubsidyProgram.value.id });
  store.commit('setFilters', ['subsidy', { subsidyProgramId: selectedSubsidyProgram.value.id }]);
}

async function setWaitlistNumber(enrollmentRequest, number) {
  await api.manager.enrollment.update(enrollmentRequest.id, { waitlist_number: number });
  await load();
}

async function toggleSort(field) {
  if (filters.value.sort_field !== field) {
    filters.value.sort_dir = 'desc';
  } else {
    filters.value.sort_dir = filters.value.sort_dir === 'asc' ? 'desc' : 'asc';
  }

  filters.value.sort_field = field;
  await load();
}

async function transferSelectedEnrollments() {
  processing.value = true;

  const providerId = selectedEnrollAllProviderId.value;
  await Promise.all(
    checkedEnrollmentRequests.value.map(async (enrollmentRequest) => {
      api.manager.enrollment.update(enrollmentRequest.id, {
        provider_id: providerId,
      });
    }),
  );

  setTimeout(async () => {
    transferSelectedEnrollmentsDialog.value?.close();
    await load();
  }, 2000);
}
</script>
