<template>
  <div>
    <v-row class="mb-2 d-flex align-center">
      <v-col
        cols="12"
        lg="9"
      >
        <ActionMenu
          @click:action:change="changeProvider($event)"
          :button-block="$vuetify.display.mdAndDown"
          :button-title="
            providers.find((provider) => provider.id == providerId)?.name || 'Select a location'
          "
          :items="providerActionItems"
          button-class="me-2 bg-super-light-grey px-3 py-2 rounded"
          button-color="black"
          button-icon-side="right"
          button-text
        />

        <ActionMenu
          v-if="providerId"
          @click:action:change="changeProgram($event)"
          :button-block="$vuetify.display.mdAndDown"
          :button-title="selectedProgram?.name || `Select a ${terms.program.toLowerCase()}`"
          :items="programActionItems"
          button-class="me-2 bg-super-light-grey px-3 py-2 rounded"
          button-color="black"
          button-icon-side="right"
          button-text
        />

        <ActionMenu
          v-if="selectedProgram"
          @click:action:change="changeOperatingLog($event)"
          :button-block="$vuetify.display.mdAndDown"
          :button-title="selectedMonth || 'Select a month'"
          :items="operatingLogActionItems"
          button-class="bg-super-light-grey px-3 py-2 rounded"
          button-color="black"
          button-icon-side="right"
          button-text
        />
      </v-col>
      <v-col
        v-if="selectedOperatingLog"
        class="d-flex justify-end"
        cols="12"
        lg="3"
      >
        <v-btn
          @click="handleOpenOperatingLog"
          :block="$vuetify.display.mdAndDown"
          :loading="processing"
          :variant="!!selectedOperatingLog.submitted_at ? 'outlined' : 'flat'"
          class="me-3"
          color="primary"
        >
          <template v-if="selectedOperatingLog.submitted_at">
            <v-icon
              class="me-2"
              icon="check"
            />
            <span>
              {{ $t('Submitted') }}
            </span>
          </template>
          <template v-else>
            <span>
              {{ $t('Submit') }}
            </span>
          </template>
        </v-btn>

        <ActionMenu
          :items="operatingLogActions"
          button-icon="more_vert"
          label="Download Data"
        />
      </v-col>
    </v-row>

    <template v-if="selectedOperatingLog">
      <AttendanceLogEditor
        @delete:attendance-log="deleteAttendanceLog($event)"
        @queue:attendance-log-change="queueAttendanceLogChange($event)"
        @queue:attendance-log-question="queueAttendanceLogQuestion($event)"
        @queue:operating-log-change="queueOperatingLogChange($event)"
        :attendance-log-questions="attendanceLogQuestions"
        :attendance-log-schema="attendanceLogSchema"
        :attendance-logs="attendanceLogs"
        :attendance-totals="attendanceTotals"
        :daily-totals="dailyTotals"
        :errors="errors"
        :operating-log="selectedOperatingLog"
        :operating-log-schema="operatingLogSchema"
        :queued-attendance-log-answers="queuedAttendanceLogAnswers"
        :queued-attendance-log-changes="queuedAttendanceLogChanges"
        :queued-attendance-log-question-id="queuedAttendanceLogQuestionId"
        :queued-operating-log-changes="queuedOperatingLogChanges"
        :submit-revision="selectedOperatingLog?.submission_revision"
      />
    </template>

    <template v-else>
      <NullState hide-new-button />
    </template>

    <AttendanceLogQuestionDialog
      @close="clearAttendanceLogQuestionQueue"
      @save="updateAttendanceLogsAnswers"
      ref="attendanceLogQuestionDialog"
      :processing="processing"
      :schema-definition="attendanceLogSchema.definition"
    />

    <ResourceDialog
      @close="clearAttendanceChangeQueue"
      ref="attendanceLogStatusDialog"
      :processing="processing"
      :title="`Set ${terms.attendance} status`"
      closeable
      hide-actions
    >
      <template #form>
        <v-btn
          v-for="status in config.attendance_statuses"
          @click="updateAttendanceLogsStatus(status)"
          :key="status.code"
          :color="status.color"
          :loading="processing"
          class="mb-2 me-2 px-4"
          size="x-large"
          variant="flat"
          block
          tile
        >
          {{ status.code }} - {{ status.title }}
        </v-btn>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="updateAttendanceLogsHours"
      ref="attendanceLogHoursDialog"
      :fields="ATTENDANCE_LOG_HOURS_FIELDS"
      :processing="processing"
      title="Set hours attended"
      closeable
    />

    <ResourceDialog
      @close="clearOperatingLogChangeQueue"
      ref="operatingLogStatusDialog"
      :processing="processing"
      title="Set operating status"
      closeable
      hide-actions
    >
      <template #form>
        <v-btn
          v-for="status in config.operating_statuses"
          @click="updateOperatingLog(status)"
          :key="status.code"
          :color="status.color"
          :loading="processing"
          class="mb-2 me-2 px-4"
          size="x-large"
          variant="flat"
          block
          tile
        >
          {{ status.code }} - {{ status.title }}
        </v-btn>
      </template>
    </ResourceDialog>

    <OperatingLogEditor @change="handleLoadOperatingLogs" />

    <ChangesQueuedAlert
      @clear:attendance-log-changes="clearAttendanceChangeQueue"
      @clear:attendance-log-question="clearAttendanceLogQuestionQueue"
      @clear:attendance-log-status="clearAttendanceLog"
      @clear:operating-log-changes="clearOperatingLogChangeQueue"
      @clear:operating-log-status="clearOperatingLog"
      @edit:attendance-log-question="openAttendanceLogQuestionDialog"
      @edit:attendance-log-status="handleEditAttendanceLogStatus"
      @edit:operating-log="operatingLogStatusDialog.open()"
      :attendance-log-schema="attendanceLogSchema"
      :queued-attendance-log-answers="queuedAttendanceLogAnswers"
      :queued-attendance-log-changes="queuedAttendanceLogChanges"
      :queued-operating-log-changes="queuedOperatingLogChanges"
      :show-attendance-log-change-queue="showAttendanceLogChangeQueue"
      :show-attendance-log-question-queue="showAttendanceLogQuestionQueue"
      :show-operating-log-change-queue="showOperatingLogChangeQueue"
    />
  </div>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AttendanceLogEditor from '@/shared/components/attendance-logs/AttendanceLogEditor.vue';
import AttendanceLogQuestionDialog from '@/shared/components/attendance-logs/AttendanceLogQuestionDialog.vue';
import ChangesQueuedAlert from '@/shared/components/ChangesQueuedAlert.vue';
import NullState from '@/shared/components/NullState.vue';
import OperatingLogEditor from '@/shared/components/OperatingLogEditor.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { validateAttendance } from '@/shared/services/attendance-validation';
import api from '@/shared/services/all_bright_finder';
import { useStore } from 'vuex';
import useAssets from '@/shared/composables/useAssets';

import useAttendance from '@/shared/composables/useAttendance';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';

const { updateQuery } = useRouterHelper();
const { terms } = useTerms();
const store = useStore();
const { assets } = useAssets();
const props = defineProps({
  subsidyProgramId: {
    type: String,
    default: null,
  },
});

const scopedApi = () => {
  return api.manager;
};

const {
  ATTENDANCE_LOG_HOURS_FIELDS,
  attendanceLogHoursDialog,
  attendanceLogStatusDialog,
  attendanceLogQuestionDialog,
  operatingLogStatusDialog,
  attendanceLogs,
  attendanceLogSchema,
  attendanceLogQuestions,
  attendanceTotals,
  errors,
  handleEditAttendanceLogStatus,
  processing,
  providerId,
  operatingLogs,
  operatingLogSchema,
  queuedAttendanceLogQuestionId,
  queuedAttendanceLogAnswers,
  queuedOperatingLogChanges,
  providers,
  programs,
  queuedAttendanceLogChanges,
  selectedOperatingLog,
  selectedProgram,
  showAttendanceLogChangeQueue,
  showAttendanceLogQuestionQueue,
  showOperatingLogChangeQueue,
  dailyTotals,
  selectedMonth,
  changeOperatingLog,
  changeOperatingLogById,
  changeProgram,
  changeProvider,
  clearAttendanceLog,
  clearOperatingLog,
  clearAttendanceChangeQueue,
  clearOperatingLogChangeQueue,
  clearAttendanceLogQuestionQueue,
  loadAttendanceLogs,
  loadAttendanceLogQuestions,
  loadOperatingLogs,
  loadProviders,
  openAttendanceLogQuestionDialog,
  queueAttendanceLogChange,
  queueAttendanceLogQuestion,
  queueOperatingLogChange,
  updateOperatingLog,
  updateAttendanceLogsAnswers,
  updateAttendanceLogsStatus,
  updateAttendanceLogsHours,
} = useAttendance(scopedApi, props.subsidyProgramId);

const config = computed(() => store.state.config);
const attendanceLink = computed(() => {
  if (!selectedOperatingLog.value) return null;

  const programName = programs.value.find(
    (program) => program.id === selectedOperatingLog.value.program_id,
  ).name;
  const filename = `${programName} ${terms.value.attendance} for ${selectedOperatingLog.value.year}-${selectedOperatingLog.value.month}`;
  return scopedApi().attendance_log.downloadUrl(filename, selectedOperatingLog.value.id);
});

const operatingLogLink = computed(() => {
  if (!selectedOperatingLog.value) return null;

  const programName = programs.value.find(
    (program) => program.id === selectedOperatingLog.value.program_id,
  ).name;
  const filename = `${programName} ${terms.value.attendance} for ${selectedOperatingLog.value.year}-${selectedOperatingLog.value.month}`;
  return scopedApi().operating_log.downloadUrl(selectedOperatingLog.value.id, filename);
});

const operatingLogActions = computed(() => {
  if (selectedOperatingLog.value) {
    return [
      { href: attendanceLink.value, title: `Download ${terms.value.attendance}` },
      { href: operatingLogLink.value, title: 'Download Form' },
    ];
  }
  return [];
});

const operatingLogActionItems = computed(() => {
  return operatingLogs.value.map((ol) => ({
    event: 'change',
    operatingLogId: ol.id,
    title: [assets.months[ol.month], ol.year].join(' '),
  }));
});

const providerActionItems = computed(() => {
  return providers.value.map((provider) => ({
    event: 'change',
    title: provider.name,
    providerId: provider.id,
  }));
});

const programActionItems = computed(() => {
  return programs.value.map((program) => ({
    event: 'change',
    title: program.name,
    programId: program.id,
  }));
});

watch(
  () => props.subsidyProgramId,
  () => {
    providerId.value = null;
    selectedProgram.value = null;
    selectedOperatingLog.value = null;
    loadProviders();
  },
);

onMounted(() => {
  loadProviders();
  loadAttendanceLogQuestions();
});

const handleLoadOperatingLogs = (operatingLog) => {
  loadOperatingLogs(operatingLog);
  changeOperatingLogById(operatingLog.id);
};

const deleteAttendanceLog = (log) => {
  if (
    confirm(
      `Are you sure you want to remove the ${terms.value.attendance} record from this classroom for ${log.name} for this month?`,
    )
  ) {
    scopedApi().attendance_log.destroy(log.id, () => loadAttendanceLogs());
  }
};

const handleOpenOperatingLog = async () => {
  errors.value = validateAttendance(
    attendanceLogs.value,
    attendanceLogQuestions.value,
    !operatingLogSchema.value.meta.disable_daily_attendance,
  );

  if (errors.value.length > 0) {
    return api.eventBus.$emit('error', [`${terms.value.attendance} log is not complete.`]);
  }

  return await updateQuery({ action: 'editOperatingLog' });
};
</script>
