<template>
  <div
    v-if="store.state.site"
    id="app"
    tabindex="-1"
  >
    <v-app>
      <template v-if="store.state.pages.Welcome.content.alert && route.name == 'Welcome'">
        <div
          class="pa-3 w-100 text-center bg-primary c-white"
          data-testid="alert"
        >
          <MarkdownContent :content="store.state.pages.Welcome.content.alert" />
        </div>
      </template>

      <v-app-bar
        v-if="route.name != 'Welcome'"
        id="navbar"
        elevation="0"
      >
        <router-link
          :to="store.getters.defaultLandingPage()"
          aria-label="Home"
          class="px-3"
        >
          <v-img
            :class="[$vuetify.display.smAndDown ? 'logo-small' : 'logo-large', 'logo-padding']"
            :src="store.state.brand.logo.small || store.state.brand.logo.full"
            data-cy="manager_logo"
          />
        </router-link>

        <template v-if="store.state.profile">
          <template v-if="$vuetify.display.mdAndUp && route.meta.registration !== true">
            <v-btn
              v-if="store.getters.hasBusinessAffiliation() && store.state.pages.Provider.enabled"
              :active="menuTabIsActive('/providers/list')"
              :class="getBtnClasses('/providers/list')"
              :to="{ name: 'ProviderIndex' }"
              data-cy="providers-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ t(store.state.pages.Provider.title || 'Locations') }}
            </v-btn>

            <v-btn
              v-if="showRequests"
              :active="menuTabIsActive('/providers/requests')"
              :class="getBtnClasses('/providers/requests')"
              :to="{ name: 'FormRequestIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="requests-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ t(store.state.pages.Requests.title || 'Requests') }}
            </v-btn>

            <v-btn
              v-if="store.getters.hasBusinessAffiliation() && store.state.pages.Interested.enabled"
              :active="menuTabIsActive('/interested')"
              :class="getBtnClasses('/interested')"
              :to="{ name: 'InterestedIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="interested-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ t(store.state.pages.Interested.title || 'Interested') }}
            </v-btn>

            <v-btn
              v-if="store.getters.hasBusinessAffiliation() && store.state.pages.Students.enabled"
              :active="menuTabIsActive('/students')"
              :class="getBtnClasses('/students')"
              :to="{ name: 'EnrollmentIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="students-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ t(store.state.pages.Students.title || 'Students') }}
            </v-btn>

            <v-btn
              v-if="store.getters.hasBusinessAffiliation() && store.state.pages.Grants.enabled"
              :active="menuTabIsActive('/grants')"
              :class="getBtnClasses('/grants')"
              :to="{ name: 'GrantProgramIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="grants-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ t(store.state.pages.Grants.title || 'Grants') }}
            </v-btn>

            <v-btn
              v-if="store.state.pages.Learning.enabled && store.state.profile.enable_courses"
              :active="menuTabIsActive('/courses')"
              :class="getBtnClasses('/courses')"
              :to="{ name: 'CourseIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="courses-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ t(store.state.pages.Learning.title || 'Learning') }}
            </v-btn>

            <v-btn
              v-if="store.getters.hasBusinessAffiliation() && store.state.pages.Assessments.enabled"
              :active="menuTabIsActive('/assessments')"
              :class="getBtnClasses('/assessments')"
              :to="{ name: 'AssessmentIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="assessments-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ t(store.state.pages.Assessments.title || 'Assessments') }}
            </v-btn>

            <v-btn
              v-if="store.state.features.enable_payments"
              :active="menuTabIsActive('/billing_center')"
              :class="getBtnClasses('/billing_center')"
              :to="{ name: 'BillingCenterIndex' }"
              class="fs-15 px-6 h-100pc"
              data-cy="billing-center-button"
              size="x-large"
              variant="text"
              tile
            >
              {{ t('Billing center') }}
            </v-btn>
          </template>

          <v-spacer />

          <LocaleSelector />

          <template v-if="showMessages">
            <v-btn
              :active="false"
              :aria-label="t('View messages')"
              :to="{ name: 'MessageIndex' }"
              class="c-black"
              data-cy="messages-button"
              variant="text"
              icon
            >
              <v-icon
                icon="forum"
                size="20"
              />
            </v-btn>
          </template>

          <v-menu
            ref="accountMenu"
            location="bottom"
            role="mainmenu"
            open-on-click
          >
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :aria-label="ariaName"
                class="c-black"
                variant="text"
                rounded
              >
                <template v-if="userProfileInitials">
                  <v-avatar
                    class="me-2"
                    color="primary"
                    size="36px"
                  >
                    <v-img
                      v-if="profilePhoto"
                      :src="profilePhoto"
                      alt="Profile Photo"
                      size="small"
                    />
                    <span
                      v-else-if="userProfileInitials"
                      :aria-hidden="!showInitials"
                      class="pa-2 font-weight-bold text-white"
                    >
                      {{ userProfileInitials }}
                    </span>
                  </v-avatar>

                  <span
                    v-if="!showInitials"
                    class="profile-name"
                  >
                    {{ store.state.profile.name }}
                  </span>
                </template>

                <v-icon
                  v-else
                  icon="menu"
                />
              </v-btn>
            </template>

            <v-list
              elevation="0"
              border
            >
              <v-list-item
                v-if="
                  store.state.pages.Settings.enabled &&
                  store.state.profile?.business_role === 'Admin'
                "
                :title="t('Settings')"
                :to="{ name: 'BusinessShow' }"
                class="fw-500 fs-16 c-black"
                color="primary"
              />

              <v-list-item
                v-if="
                  store.state.pages.Settings.enabled &&
                  store.state.profile?.business_role === 'Admin'
                "
                :title="t('Staff')"
                :to="{ name: 'StaffIndex' }"
                class="fw-500 fs-16 c-black"
                color="primary"
              />

              <v-list-item
                :title="t('My profile')"
                :to="{ name: 'Profile' }"
                class="fw-500 fs-16 c-black"
                color="primary"
              />

              <v-list-item
                v-if="store.state.pages.Navigation.links.faq"
                :href="store.state.pages.Navigation.links.faq"
                :title="t('FAQ')"
                class="fw-500 fs-16"
                color="primary"
              />

              <v-list-item
                v-if="store.getters.hasBusinessAffiliation()"
                :title="t('My Documents')"
                :to="{ name: 'DocumentIndex' }"
                class="fw-500 fs-16"
                color="primary"
              />

              <v-list-item
                id="menu_terms"
                :title="t('Terms of Service')"
                class="fw-500 fs-16"
                href="https://www.getbridgecare.com/terms-of-service"
                target="_blank"
                tracked
              />

              <v-list-item
                id="menu_privacy"
                :title="t('Privacy Policy')"
                class="fw-500 fs-16"
                color="primary"
                href="https://www.getbridgecare.com/privacy-policy"
                target="_blank"
                tracked
              />

              <v-list-item
                v-if="customLink1"
                id="link_1_link"
                :href="customLink1['url']"
                :title="t(customLink1['name'])"
                color="primary"
                target="_blank"
              />

              <v-list-item
                v-if="customLink2"
                id="link_2_link"
                :href="customLink2['url']"
                :title="t(customLink2['name'])"
                color="primary"
                target="_blank"
              />

              <v-list-item
                id="account_menu_signout"
                :title="t('Sign out')"
                :to="{ name: 'SignOut' }"
                class="fw-500 fs-16"
                color="primary"
                tracked
              />
            </v-list>
          </v-menu>
        </template>

        <template v-else>
          <v-spacer />

          <LocaleSelector />
        </template>
      </v-app-bar>

      <SnackBar
        v-model="chime"
        :centered="true"
        :text="chimeText"
        :timeout="chimeTime"
        rounded
      />

      <v-main class="bg-super-light-blue">
        <router-view v-slot="{ Component }">
          <component
            :is="Component"
            @chime="regularChime"
            @error="error"
            @replace="router.push($event)"
          />
        </router-view>
      </v-main>

      <v-bottom-navigation
        v-if="store.state.profile && $vuetify.display.smAndDown && route.meta.registration !== true"
        grow
      >
        <v-btn
          v-if="store.state.pages.Provider.enabled"
          :active="menuTabIsActive('/providers/list')"
          :class="menuTabIsActive('/providers/list') ? 'c-primary' : ''"
          :to="{ name: 'ProviderIndex' }"
          append-icon="home"
          data-cy="bottom-providers-button"
        >
          {{ t(store.state.pages.Provider.title || 'Locations') }}
        </v-btn>

        <v-btn
          v-if="store.getters.hasProviderRequestableFormSchemas()"
          :active="menuTabIsActive('/providers/requests')"
          :class="menuTabIsActive('/providers/requests') ? 'c-primary' : ''"
          :to="{ name: 'FormRequestIndex' }"
          append-icon="article"
          data-cy="bottom-requests-button"
        >
          {{ t(store.state.pages.Requests.title || 'Requests') }}
        </v-btn>

        <v-btn
          v-if="store.state.pages.Interested.enabled"
          :active="menuTabIsActive('/interested')"
          :class="menuTabIsActive('/interested') ? 'c-primary' : ''"
          :to="{ name: 'InterestedIndex' }"
          append-icon="favorite"
          data-cy="bottom-interested-button"
        >
          {{ t(store.state.pages.Interested.title || 'Interested') }}
        </v-btn>

        <v-btn
          v-if="store.state.pages.Students.enabled"
          :active="menuTabIsActive('/students')"
          :class="menuTabIsActive('/students') ? 'c-primary' : ''"
          :to="{ name: 'EnrollmentIndex' }"
          append-icon="face"
          data-cy="bottom-students-button"
        >
          {{ t(store.state.pages.Students.title || 'Students') }}
        </v-btn>

        <v-btn
          v-if="store.state.pages.Learning.enabled"
          :active="menuTabIsActive('/courses')"
          :class="menuTabIsActive('/courses') ? 'c-primary' : ''"
          :to="{ name: 'CourseIndex' }"
          append-icon="book"
          data-cy="bottom-courses-button"
        >
          {{ t(store.state.pages.Learning.title || 'Learning') }}
        </v-btn>

        <v-btn
          v-if="store.getters.hasBusinessAffiliation() && store.state.pages.Grants.enabled"
          :active="menuTabIsActive('/grants')"
          :class="menuTabIsActive('/grants') ? 'c-primary' : ''"
          :to="{ name: 'GrantProgramIndex' }"
          append-icon="account_balance"
          data-cy="bottom-grants-button"
        >
          {{ t(store.state.pages.Grants.title || 'Grants') }}
        </v-btn>

        <v-btn
          v-if="store.getters.hasBusinessAffiliation() && store.state.pages.Assessments.enabled"
          :active="menuTabIsActive('/assessments')"
          :class="menuTabIsActive('/assessments') ? 'c-primary' : ''"
          :to="{ name: 'AssessmentIndex' }"
          append-icon="star_rate"
          data-cy="bottom-assessments-button"
        >
          {{ t(store.state.pages.Assessments.title || 'Assessments') }}
        </v-btn>

        <!-- Temporarily hiding as it is too large for bottom nav/tests -->
        <!-- <v-btn
          v-if="store.state.features.enable_payments"
          :active="menuTabIsActive('/billing_center')"
          :class="menuTabIsActive('/billing_center') ? 'c-primary' : ''"
          :to="{ name: 'BillingCenterIndex' }"
          append-icon="receipt_long"
          data-cy="bottom-billing-center-button"
        >
          {{ t('Billing') }}
        </v-btn> -->
      </v-bottom-navigation>

      <!-- <UserSessionTimeout /> -->
    </v-app>
  </div>
</template>

<script setup>
import { provide } from 'vue';
import LocaleSelector from '@/shared/components/LocaleSelector.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import SnackBar from '@/shared/components/SnackBar.vue';
// import UserSessionTimeout from '@/shared/components/UserSessionTimeout.vue';
import SegmentService from '@/shared/services/analytics/SegmentService';
import useSegmentPageViews from '@/shared/composables/analytics/useSegmentPageViews';
import { SEGMENT_PROVIDE_KEY } from '@/shared/composables/analytics/useSegment';
import { toAcronym } from '@/shared/services/string-utils';
import useChime from '@/shared/composables/useChime';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import useLanguageHelper from '@/shared/composables/useLanguageHelper';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const segment = SegmentService.forProvider(window.analytics);
provide(SEGMENT_PROVIDE_KEY, segment);
useSegmentPageViews(segment);
useLanguageHelper();

const { chime, chimeText, chimeTime, error, regularChime } = useChime();
const display = useDisplay();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();

defineEmits(['click']);

onMounted(() => {
  if (route.query.chime) {
    regularChime(route.query.chime);
  }
});

const ariaName = computed(() => {
  const defaultLabel = t('Open account settings menu');
  const profileDetails = showInitials.value
    ? `${t('Initials are')} ${userProfileInitials.value}, ${t('full name is')} ${store.state.profile.name}`
    : `${t('Name is')} ${store.state.profile.name}`;
  return `${defaultLabel}, ${profileDetails}`;
});

const customLink1 = computed(() => {
  if (!store.state.pages.Navigation) return null;
  if (!store.state.pages.Navigation?.link_1_url) return null;
  if (!store.state.pages.Navigation?.link_1_name) return null;

  return {
    name: store.state.pages.Navigation.link_1_name,
    url: store.state.pages.Navigation.link_1_url,
  };
});

const customLink2 = computed(() => {
  if (!store.state.pages.Navigation) return null;
  if (!store.state.pages.Navigation?.link_2_url) return null;
  if (!store.state.pages.Navigation?.link_2_name) return null;

  return {
    name: store.state.pages.Navigation.link_2_name,
    url: store.state.pages.Navigation.link_2_url,
  };
});

const profilePhoto = computed(() => store.state.profile.photo);
const showInitials = computed(() => display.mdAndDown.value);

const showMessages = computed(() => {
  return (
    store.getters.hasBusinessAffiliation() &&
    store.state.pages.Messaging.enabled &&
    store.state.profile.business_confirmed
  );
});

const showRequests = computed(() => {
  return (
    store.getters.hasBusinessAffiliation() && store.getters.hasProviderRequestableFormSchemas()
  );
});

const userProfileInitials = computed(() => toAcronym(store.state.profile.name));

function getBtnClasses(routeName) {
  if (!menuTabIsActive(routeName)) return 'fs-15 px-6 h-100pc';
  return 'c-primary fs-15 px-6 h-100pc';
}

function menuTabIsActive(name) {
  return route.path.startsWith(name);
}
</script>

<style scoped>
.logo-small {
  min-width: 100px;
}

.logo-large {
  min-width: 140px;
  min-height: 50px;
}

.logo-padding {
  padding-left: 10px;
}

.profile-name {
  min-width: 100px;
}

@media (max-width: 600px) {
  .profile-name {
    min-width: 100px;
  }
}
</style>
