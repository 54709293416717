<template>
  <div class="bg-white w-100pc h-100pc">
    <v-container
      v-if="loaded"
      class="mxw-800 mx-auto py-12"
    >
      <div
        ref="progressRef"
        :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
        class="px-4 focus-invisible"
        role="progressbar"
        tabindex="0"
        aria-live
      >
        <v-progress-linear
          v-model="progress"
          :indeterminate="!loaded"
          class="focus-invisible mb-4"
          color="primary"
        />
      </div>

      <div class="px-4">
        <QuestionSet
          v-model="form.attributes"
          @back="backFromQuestion($event)"
          @next="forwardFromQuestion($event)"
          :processing="processing"
          :questions="validQuestions"
          :readonly="form.attributes.locked"
          :schema="formSchema.definition"
          :section="section"
          :transition-name="transitionName"
          key-name="question"
        />

        <transition-group :name="transitionName">
          <FormQuestion
            v-show="section == 'finish'"
            @back="backFromFinish()"
            @finish="finish"
            key="finish"
            :finish-text="form.attributes.locked ? 'Done' : 'Confirm and submit'"
            :forwardable="false"
            :processing="processing"
            section="Finish"
            title="You're all done!"
            finishable
          >
            <p>
              {{ $t("Click 'Confirm and submit' to finalize your application.") }}
            </p>
          </FormQuestion>
        </transition-group>
      </div>
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useStepper from '@/shared/composables/useStepper';
import { getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const instance = getCurrentInstance();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { updateQuery } = useRouterHelper();

const form = ref(null);
const formSchema = ref(null);
const processing = ref(false);
const progressRef = ref(null);

const { progress, section, stepBack, stepForward, stepCount, stepTotal, transitionName } =
  useStepper({
    processing,
    route,
    updateQuery,
  });

const schemaId = computed(() => route.params.schemaId);

const { loadQuestions, questions, validateAnswers, validQuestions } = useQuestionable({
  ownerDataTypeRef: ref('Schema'),
  ownerIdRef: schemaId,
  syncedObjectRef: formSchema,
});

watch(
  () => route.query.section,
  async (newValue) => {
    section.value = null;
    stepCount.value = parseInt(route.query.step, 10);
    if (!stepTotal.value) await validateAnswers();

    recalculateStepTotal();

    setTimeout(() => {
      if (newValue) {
        section.value = newValue;
        progressRef.value?.focus();
      } else {
        section.value = 'question-0';
      }
      progress.value = (stepCount.value / stepTotal.value) * 100;
      instance?.proxy?.$forceUpdate();
    }, 600);
  },
);

const loaded = computed(() => {
  return form.value && formSchema.value && questions.value?.length > 0;
});

watch(
  loaded,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      stepCount.value = parseInt(route.query.step, 10);
      if (!stepTotal.value) await validateAnswers();

      recalculateStepTotal();

      if (route.query.section) {
        setTimeout(() => {
          section.value = route.query.section;
          progressRef.value?.focus();
          progress.value = (stepCount.value / stepTotal.value) * 100;
          instance?.proxy?.$forceUpdate();
        }, 500);
      } else {
        router.push({ query: { section: 'question-0', step: 1 } });
      }
    }
  },
  { immediate: true },
);

function backFromFinish() {
  processing.value = true;
  stepBack(`question-${validQuestions.value.length - 1}`);
}

async function backFromQuestion(index) {
  processing.value = true;
  if (!form.value.attributes.locked) {
    await saveForm();
    await validateAnswers();
  }
  recalculateStepTotal();

  if (index - 1 < 0) {
    router.push({ name: 'Dashboard' });
  } else {
    stepBack(`question-${index - 1}`);
  }
}

async function finish() {
  processing.value = true;
  if (!form.value.attributes.locked) {
    form.value.attributes.submitted = true;
    await Api.manager.form.update(form.value.id, form.value.attributes);
  }
  router.replace({ name: 'RegisterAccess' });
}

async function forwardFromQuestion(index) {
  processing.value = true;
  if (!form.value.attributes.locked) {
    await saveForm();
    await validateAnswers();
  }
  recalculateStepTotal();

  if (index + 1 >= validQuestions.value.length) {
    stepForward('finish');
  } else {
    stepForward(`question-${index + 1}`);
  }
}

function load() {
  formSchema.value = store.state.schemas[route.params.schemaId];
  void loadOnboardingForm();
}

async function loadOnboardingForm() {
  const onboardingFormSchemaId = route.params.schemaId;
  const response = await Api.manager.form.index({ 'filter[schema]': onboardingFormSchemaId });
  const forms = response.data.data || [];
  form.value = forms[forms.length - 1];

  if (!form.value) {
    const createResponse = await Api.manager.form.create({
      data: {
        relationships: {
          schema: {
            data: {
              id: onboardingFormSchemaId,
            },
          },
        },
      },
    });

    form.value = createResponse.data.data;
  }

  loadQuestions(() => {
    recalculateStepTotal();
  });
}

function recalculateStepTotal() {
  stepTotal.value = validQuestions.value.length + 1;
  progress.value = (stepCount.value / stepTotal.value) * 100;
}

async function saveForm() {
  const { data } = await Api.manager.form.update(form.value.id, form.value.attributes);
  form.value = data.data;
}

onMounted(load);
</script>
