<template>
  <div>
    <template v-if="payments">
      <v-row class="mb-0">
        <v-col />

        <v-col class="ta-right">
          <FilterMenu
            :actions="false"
            :title="filters.status"
            classes="ms-3"
            borderless
            left
            outlined
            paddingless
          >
            <template #card>
              <v-list
                v-model:selected="filters.status"
                density="compact"
              >
                <v-list-item
                  v-for="status in statuses"
                  :key="status"
                  :title="$t(status)"
                  :value="status"
                  color="primary"
                  data-cy="availability-checkmark"
                >
                  <template #prepend="{ isSelected, select }">
                    <v-checkbox-btn
                      @update:model-value="select"
                      :model-value="isSelected"
                      density="compact"
                      tabindex="-1"
                    >
                      <template #input="{ inputNode, icon }">
                        <v-icon
                          :icon="icon"
                          size="x-small"
                        />
                        <component :is="inputNode" />
                      </template>
                    </v-checkbox-btn>
                  </template>
                </v-list-item>
              </v-list>
            </template>
          </FilterMenu>

          <FilterMenu
            ref="providerFilter"
            :actions="false"
            classes="ms-3"
            title="Location"
            width="400"
            borderless
            left
            outlined
          >
            <template #card>
              <v-autocomplete
                v-model="filters.provider_id"
                @update:model-value="$refs.providerFilter.close()"
                :item-title="(item) => (item.name + ' - ' + item.address1 + ')').substring(0, 45)"
                :items="providers"
                :placeholder="$t('Search by name or address')"
                item-value="id"
                variant="filled"
                auto-select-first
                chips
                closable-chips
                left
              />
            </template>
          </FilterMenu>
        </v-col>
      </v-row>

      <template v-if="$vuetify.display.mdAndUp">
        <v-card
          class="bb-1 pa-0"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row>
              <SortableTableHeader
                cols="3"
                field="name"
                title="Child's name"
              />
              <SortableTableHeader
                cols="1"
                field="dob"
                title="Amount"
              />
              <SortableTableHeader
                cols="2"
                field="Date"
                title="Date"
              />
              <SortableTableHeader
                cols="3"
                field="period"
                title="Period"
              />
              <SortableTableHeader
                cols="3"
                field="provider_id"
                title="Location"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </template>

      <template v-if="payments.length == 0">
        <div class="mxw-400 mx-auto pa-8">
          <img
            alt=""
            class="w-100pc"
            src="https://res.cloudinary.com/bridgecare/image/upload/v1627417457/media/interested_null_hibteo.svg"
          />
        </div>

        <div class="mxw-600 mx-auto pa-8 ta-center">
          <div
            v-t="'This is where you can track all payments for students enrolled in your program.'"
            class="fs-22 fw-600 mb-4"
          />
          <!-- replace 'enrolled' with termed version? -->
          <div
            v-t="'You can track historical payments as well as scheduled payments.'"
            class="fs-18 fw-500 mb-6"
          />
        </div>
      </template>
      <template v-else>
        <PaymentRow
          v-for="(payment, index) in payments"
          :key="index"
          :enrollment="enrollments.find((enrollment) => enrollment.child_id == payment.child_id)"
          :payment="payment"
          :provider="providers.find((provider) => provider.id == payment.provider_id)"
          class="mt-2"
        />
      </template>
    </template>
    <template v-else>
      <v-progress-linear
        class="mt-6"
        indeterminate
      />
    </template>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import PaymentRow from '@/manager/components/PaymentRow.vue';

const enrollments = ref([]);
const filters = ref({ status: 'Completed' });
const completedPayments = ref(null);
const providers = ref([]);
const upcomingPayments = ref(null);
const statuses = ref(['Completed', 'Upcoming']);
const subsidyAwards = ref([]);

const payments = computed(() => {
  if (filters.value.status === 'Completed') {
    return completedPayments.value;
  }
  return upcomingPayments.value;
});

onMounted(async () => {
  load();
  loadEnrollments();
  await loadProviders();
  loadSubsidyAwards();
});

// function download(format) {
//   window.open(Api.manager.payment.downloadUrl(filters.value, 'Payments', format));
// }

function load() {
  completedPayments.value = [];
}

function loadEnrollments() {
  Api.manager.enrollment.index({}, (resp) => {
    enrollments.value = resp.data;
  });
}

async function loadProviders() {
  const { data } = await Api.manager.provider.index();
  providers.value = data;
}

function loadSubsidyAwards() {
  Api.manager.subsidy_award.index({}, (resp) => {
    subsidyAwards.value = resp.data;
    upcomingPayments.value = resp.data
      .map((sa) =>
        sa.payments.map((paymentObject) => {
          const payment = paymentObject;
          payment.child_id = sa.child_id;
          payment.provider_id = sa.provider_id;
          return payment;
        }),
      )
      .flat()
      .sort((a, b) => (a.date > b.date ? 1 : -1));
  });
}
</script>
